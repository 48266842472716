// src/features/settingsSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  musicOn: true,
  themeMode: 'light',
  selfReading: false,
  language: 'english',
  textSize: 100,
  fontFamily: 'Arial, sans-serif',
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleMusic: (state) => {
      state.musicOn = !state.musicOn;
    },
    toggleThemeMode: (state) => {
      state.themeMode = state.themeMode === 'light' ? 'dark' : 'light';
    },
    setThemeMode: (state, action) => {
      state.themeMode = action.payload;
    },
    setMusicOn: (state, action) => {
      state.musicOn = action.payload;
    },
    toggleSelfReading: (state) => {
      state.selfReading = !state.selfReading;
    },
    setSelfReading: (state, action) => {
      state.selfReading = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    increaseTextSize: (state) => {
      state.textSize = Math.min(state.textSize + 10, 150); // Max 150%
    },
    decreaseTextSize: (state) => {
      state.textSize = Math.max(state.textSize - 10, 50); // Min 50%
    },
    setFontFamily: (state, action) => {
      state.fontFamily = action.payload;
    },
  },
});

export const {
  toggleMusic,
  toggleThemeMode,
  setThemeMode,
  setMusicOn,
  toggleSelfReading,
  setSelfReading,
  setLanguage,
  increaseTextSize, 
  decreaseTextSize,
  setFontFamily,
} = settingsSlice.actions;
export default settingsSlice.reducer;
